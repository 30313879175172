<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <!-- <h2 class="brand-text text-primary ml-1">Vuexy</h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <!-- <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid rounded :src="imgUrl" alt="Login V2" class="imageSize" />
        </div>

        <b-card-title class="mb-1" title-tag="h2">
          SIPOLNAKES (Surat Izin Praktek Online Tenaga Kesehatan) adalah suatu
          bentuk transformasi digital pelayanan perizinan praktek tenaga
          kesehatan di Kota Salatiga yang bertujuan mewujudkan pelayanan yang
          Cepat, Mudah, Akuntabel dan Bebas Gratifikasi
        </b-card-title>
      </b-col> -->
      <!-- /Left Text-->

      <b-col lg="8" class="d-flex align-items-center auth-bg px-2 p-lg-2">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <!-- header -->
          <b-card-title class="mb-1 font-weight-bold text-left" title-tag="h2">
            SIPOLNAKES 👋🏻
          </b-card-title>
          <b-card-text class="mb-1 text-left">
            SIPOLNAKES (Surat Izin Praktek Online Tenaga Kesehatan) adalah suatu
            bentuk transformasi digital pelayanan perizinan praktek tenaga
            kesehatan di Kota Salatiga yang bertujuan mewujudkan pelayanan yang
            Cepat, Mudah, Akuntabel dan Bebas Gratifikasi
          </b-card-text>

          <!-- layanan -->
          <b-card-title class="mb-1 font-weight-bold text-left" title-tag="h2">
            Layanan SIPOLNAKES
          </b-card-title>
          <b-card-text class="mb-1 text-left">
            1. Pembuatan Surat Izin Praktik (SIP)
          </b-card-text>
          <b-card-text class="mb-1 text-left">
            2. Pembuatan Duplikat Surat Izin Praktik (SIP)
          </b-card-text>
          <b-card-text class="mb-1 text-left">
            3. Perubahan data Surat Izin Praktik (SIP)
          </b-card-text>
          <b-card-text class="mb-1 text-left">
            4. Pencabutan Surat Izin Praktik (SIP)
          </b-card-text>

          <!-- panduan -->
          <b-card-title class="mb-1 font-weight-bold text-left" title-tag="h2">
            Panduan SIPOLNAKES
          </b-card-title>
          <b-card-text class="mb-1 text-left">
            Manual Book :

            <a
              href="https://drive.google.com/file/d/1eZVBe0O1A2UdPGnkp97D1OMh7akP-YRY/view"
              target="_blank"
            >
              <b> Klik Disini </b>
            </a>
          </b-card-text>

          <!-- cek masa berlaku -->
          <b-form>
            <b-input-group prepend="Cek masa berlaku SIP" class="mb-1">
              <b-form-input
                id="cek-sip"
                name="cek-sip"
                placeholder="Masukkan ID SIP"
                type="text"
                v-model="nomorSIP"
              />
              <b-input-group-append>
                <b-button variant="info" @click="cekSIP()"> Cek SIP </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form>

          <b-card-text class="mb-1 text-center" v-if="adaSIP === true">
            Status : <b> Aktif </b>
            <br />
            Atas Nama : <b>{{ this.hasilSIP }} </b>
          </b-card-text>

          <b-card-text
            class="mb-1 text-center text-danger"
            v-if="tidakAdaSIP === true"
          >
            <b> {{ this.hasilTidakAdaSIP }} </b>
          </b-card-text>

          <!-- helpdesk -->
          <b-card-title class="mb-1 font-weight-bold text-left" title-tag="h2">
            Helpdesk SIPOLNAKES
          </b-card-title>
          <b-card-text class="mb-1 text-left">
            Telp/ whatsApp : +62 895-3959-07373
          </b-card-text>
          <b-card-text class="mb-1 text-left">
            Email : rapk.dkksalatiga@gmail.com
          </b-card-text>

          <!-- Layanan pengaduan, Saran dan Masukan :  -->
          <b-card-title class="mb-1 font-weight-bold text-left" title-tag="h2">
            Layanan pengaduan, Saran dan Masukan
          </b-card-title>

          <b-card-text class="mb-1 text-left">
            Telp : (0298) 326146
          </b-card-text>
          <b-card-text class="mb-1 text-left">
            Facsimile : (0298) 322697
          </b-card-text>
          <b-card-text class="mb-1 text-left">
            WhatsApp : +62 895-3959-07373
          </b-card-text>
          <b-card-text class="mb-1 text-left">
            Email 1 : dinkes@salatiga.go.id
          </b-card-text>
          <b-card-text class="mb-1 text-left">
            Email 2 : rapk.dkksalatiga@gmail.com
          </b-card-text>

          <!-- alamat kantor -->
          <b-card-title class="mb-1 font-weight-bold text-left" title-tag="h2">
            Alamat Kantor
          </b-card-title>
          <b-card-text class="mb-1 text-left">
            Dinas Kesehatan Kota Salatiga, Jl Hasanudin 110 A Salatiga
          </b-card-text>
          <b-card-text class="mb-1 text-left">
            Mall Pelayanan Publik (MPP) Smart
          </b-card-text>
        </b-col>
      </b-col>

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <div
            class="w-100 d-lg-flex align-items-center justify-content-center mb-2"
          >
            <b-img
              fluid
              rounded
              :src="imgUrl"
              alt="Login V2"
              class="imageSize"
            />
          </div>

          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            Selamat Datang 👋🏻
          </b-card-title>
          <b-card-text class="mb-2">
            Silahkan Login dengan email dan password yang sudah ada
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="NIK" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="NIK"
                  vid="email"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userNIK"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="Masukkan NIK"
                    type="number"
                    @input="updateValueNIK()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>

                  <small class="text-danger" v-if="userNIK.length > 0 && errorNIK === true">
                    NIK harus valid 16 digit angka
                  </small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>Lupa Password ?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Masukkan Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Login
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Belum mempunyai akun ? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>Buat Akun</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userNIK: "",
      // sideImg: require("@/assets/images/pages/login-default-new-2.jpeg"),
      sideImg: require("@/assets/images/pages/logo_sipolnakes_new.jpg"),

      // validation rules
      required,
      email,

      errorNIK: false,
      adaSIP: false,
      tidakAdaSIP: false,

      nomorSIP: "",
      hasilSIP: "",
      hasilTidakAdaSIP: "",

      auth: {
        id: 1,
        fullName: "John Doe",
        username: "johndoe",
        password: "admin",
        // eslint-disable-next-line global-require
        avatar: require("@/assets/images/avatars/13-small.png"),
        email: "admin@demo.com",
        role: "admin",
        ability: [
          {
            action: "manage",
            subject: "all",
          },
        ],
      },
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/login-default-new-2.jpeg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    updateValueNIK() {
      if (this.userNIK.length === 16) {
        this.errorNIK = false;
      } else {
        this.errorNIK = true;
      }
    },
    login() {
      if (this.userNIK.length === 16) {
        this.$refs.loginForm.validate().then((success) => {
          if (success) {
            useJwt
              .login({
                username: this.userNIK,
                password: this.password,
              })
              .then((response) => {
                const { userData } = this.auth;
                useJwt.setToken(response.data.access_token);
                useJwt.setRefreshToken(response.data.access_token);
                localStorage.setItem("userData", JSON.stringify(this.auth));

                this.$ability.update(this.auth.ability);

                localStorage.setItem("id_pendaftar", response.data.id);
                localStorage.setItem("sip_stts", response.data.status);
                localStorage.setItem("email_akun", response.data.email);

                this.$router
                  .replace(getHomeRouteForLoggedInUser(response.data.role))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Selamat Datang`,
                        icon: "CheckCircleIcon",
                        variant: "success",
                        text: `Anda telah berhasil login`,
                      },
                    });
                  });
              })
              .catch((error) => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `${error.response.data.detail}`,
                    icon: "XCircleIcon",
                    variant: "danger",
                    text: `${error.response.data.detail}`,
                  },
                });
              });
          }
        });
      } else {
        this.errorNIK = true;
      }
    },

    cekSIP() {
      // ini cek sip
      return new Promise(() => {
        axios
          .get("admin/cek_sip?nomor_sip=" + this.nomorSIP)
          .then((res) => {
            let hasilSIP1 = res.data.nama_gelar.substr(0, 4);
            let hasilSIP2 = res.data.nama_gelar.replace(/.(?=.{2})/g, "x");
            this.hasilSIP = hasilSIP1 + hasilSIP2;
            this.adaSIP = true;
            this.tidakAdaSIP = false;
          })

          .catch((error) => {
            this.adaSIP = false;
            this.tidakAdaSIP = true;

            this.hasilTidakAdaSIP = error.response.data.detail;

            // this.$toast({
            //   component: ToastificationContent,
            //   position: "top-right",
            //   props: {
            //     title: error.response.data.detail,
            //     icon: "XCircleIcon",
            //     variant: "danger",
            //   },
            // });
          });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.imageSize {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 2px;
  width: 250px;
}
</style>
